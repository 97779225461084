







import { Component, Vue } from 'vue-property-decorator'
import KYForm from '@/components/KYForm.vue'
import AlertDialog from '@/components/AlertDialog.vue'

@Component({
	components: {
		KYForm,
		AlertDialog,
	},
})
export default class KY extends Vue {
}

