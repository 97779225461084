






















import { Component, Prop, Vue } from 'vue-property-decorator'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import { AllowedCountryListAlpha2, CountryListAlpha2 } from '@/locales/countries'

@Component({
	computed: {
		CountryListAlpha2 () {
			return CountryListAlpha2
		},
		AllowedCountryListAlpha2 () {
			return AllowedCountryListAlpha2
		},
	},
})
export default class CustomAutocomplete extends Vue {
	@Prop() value!: string

	@Prop() label?: string | VueI18n.TranslateResult

	@Prop() rules?: ((v: string) => boolean | string | LocaleMessages)[] | boolean

	@Prop() allowedCountryOnly = false

	@Prop() disabled: boolean = false

	private get sortedCountries (): string[] {
		return Object.keys((this.allowedCountryOnly ? AllowedCountryListAlpha2 : CountryListAlpha2)).sort((a, b) => {
			return this.getTranslation(a).localeCompare(this.getTranslation(b))
		})
	}

	private countrySearch (item: string, queryText: string) {
		const translatedText = this.$t('countries.' + item.toLowerCase()) as string
		return translatedText.toLowerCase().includes(queryText.toLowerCase())
	}

	private getTranslation (country: string): string {
		return this.$t('countries.' + country.toString().toLowerCase()).toString()
	}
}
