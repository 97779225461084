export enum CountryListAlpha2 {
	AF = 'AF',
	AL = 'AL',
	DZ = 'DZ',
	AS = 'AS',
	AD = 'AD',
	AO = 'AO',
	AI = 'AI',
	AQ = 'AQ',
	AG = 'AG',
	AR = 'AR',
	AM = 'AM',
	AW = 'AW',
	AU = 'AU',
	AT = 'AT',
	AZ = 'AZ',
	BS = 'BS',
	BH = 'BH',
	BD = 'BD',
	BB = 'BB',
	BY = 'BY',
	BE = 'BE',
	BZ = 'BZ',
	BJ = 'BJ',
	BM = 'BM',
	BT = 'BT',
	BO = 'BO',
	BQ = 'BQ',
	BA = 'BA',
	BW = 'BW',
	BV = 'BV',
	BR = 'BR',
	IO = 'IO',
	BN = 'BN',
	BG = 'BG',
	BF = 'BF',
	BI = 'BI',
	CV = 'CV',
	KH = 'KH',
	CM = 'CM',
	CA = 'CA',
	KY = 'KY',
	CF = 'CF',
	TD = 'TD',
	CL = 'CL',
	CN = 'CN',
	CX = 'CX',
	CC = 'CC',
	CO = 'CO',
	KM = 'KM',
	CD = 'CD',
	CG = 'CG',
	CK = 'CK',
	CR = 'CR',
	HR = 'HR',
	CU = 'CU',
	CW = 'CW',
	CY = 'CY',
	CZ = 'CZ',
	CI = 'CI',
	DK = 'DK',
	DJ = 'DJ',
	DM = 'DM',
	DO = 'DO',
	EC = 'EC',
	EG = 'EG',
	SV = 'SV',
	GQ = 'GQ',
	ER = 'ER',
	EE = 'EE',
	SZ = 'SZ',
	ET = 'ET',
	FK = 'FK',
	FO = 'FO',
	FJ = 'FJ',
	FI = 'FI',
	FR = 'FR',
	GF = 'GF',
	PF = 'PF',
	TF = 'TF',
	GA = 'GA',
	GM = 'GM',
	GE = 'GE',
	DE = 'DE',
	GH = 'GH',
	GI = 'GI',
	GR = 'GR',
	GL = 'GL',
	GD = 'GD',
	GP = 'GP',
	GU = 'GU',
	GT = 'GT',
	GG = 'GG',
	GN = 'GN',
	GW = 'GW',
	GY = 'GY',
	HT = 'HT',
	HM = 'HM',
	VA = 'VA',
	HN = 'HN',
	HK = 'HK',
	HU = 'HU',
	IS = 'IS',
	IN = 'IN',
	ID = 'ID',
	IR = 'IR',
	IQ = 'IQ',
	IE = 'IE',
	IM = 'IM',
	IL = 'IL',
	IT = 'IT',
	JM = 'JM',
	JP = 'JP',
	JE = 'JE',
	JO = 'JO',
	KZ = 'KZ',
	KE = 'KE',
	KI = 'KI',
	KP = 'KP',
	KR = 'KR',
	KW = 'KW',
	KG = 'KG',
	LA = 'LA',
	LV = 'LV',
	LB = 'LB',
	LS = 'LS',
	LR = 'LR',
	LY = 'LY',
	LI = 'LI',
	LT = 'LT',
	LU = 'LU',
	MO = 'MO',
	MG = 'MG',
	MW = 'MW',
	MY = 'MY',
	MV = 'MV',
	ML = 'ML',
	MT = 'MT',
	MH = 'MH',
	MQ = 'MQ',
	MR = 'MR',
	MU = 'MU',
	YT = 'YT',
	MX = 'MX',
	FM = 'FM',
	MD = 'MD',
	MC = 'MC',
	MN = 'MN',
	ME = 'ME',
	MS = 'MS',
	MA = 'MA',
	MZ = 'MZ',
	MM = 'MM',
	NA = 'NA',
	NR = 'NR',
	NP = 'NP',
	NL = 'NL',
	NC = 'NC',
	NZ = 'NZ',
	NI = 'NI',
	NE = 'NE',
	NG = 'NG',
	NU = 'NU',
	NF = 'NF',
	MK = 'MK',
	MP = 'MP',
	NO = 'NO',
	OM = 'OM',
	PK = 'PK',
	PW = 'PW',
	PS = 'PS',
	PA = 'PA',
	PG = 'PG',
	PY = 'PY',
	PE = 'PE',
	PH = 'PH',
	PN = 'PN',
	PL = 'PL',
	PT = 'PT',
	PR = 'PR',
	QA = 'QA',
	RE = 'RE',
	RO = 'RO',
	RU = 'RU',
	RW = 'RW',
	BL = 'BL',
	SH = 'SH',
	KN = 'KN',
	LC = 'LC',
	MF = 'MF',
	PM = 'PM',
	VC = 'VC',
	WS = 'WS',
	SM = 'SM',
	ST = 'ST',
	SA = 'SA',
	SN = 'SN',
	RS = 'RS',
	SC = 'SC',
	SL = 'SL',
	SG = 'SG',
	SX = 'SX',
	SK = 'SK',
	SI = 'SI',
	SB = 'SB',
	SO = 'SO',
	ZA = 'ZA',
	GS = 'GS',
	SS = 'SS',
	ES = 'ES',
	LK = 'LK',
	SD = 'SD',
	SR = 'SR',
	SJ = 'SJ',
	SE = 'SE',
	CH = 'CH',
	SY = 'SY',
	TW = 'TW',
	TJ = 'TJ',
	TZ = 'TZ',
	TH = 'TH',
	TL = 'TL',
	TG = 'TG',
	TK = 'TK',
	TO = 'TO',
	TT = 'TT',
	TN = 'TN',
	TR = 'TR',
	TM = 'TM',
	TC = 'TC',
	TV = 'TV',
	UG = 'UG',
	UA = 'UA',
	AE = 'AE',
	GB = 'GB',
	US = 'US',
	UM = 'UM',
	UY = 'UY',
	UZ = 'UZ',
	VU = 'VU',
	VE = 'VE',
	VN = 'VN',
	VG = 'VG',
	VI = 'VI',
	WF = 'WF',
	EH = 'EH',
	YE = 'YE',
	ZM = 'ZM',
	ZW = 'ZW'
}

export enum AllowedCountryListAlpha2 {
	FR = 'FR',
	DE = 'DE',
	AT = 'AT',
	BE = 'BE',
	BG = 'BG',
	CY = 'CY',
	HR = 'HR',
	DK = 'DK',
	ES = 'ES',
	EE = 'EE',
	FI = 'FI',
	GR = 'GR',
	HU = 'HU',
	IE = 'IE',
	IT = 'IT',
	LV = 'LV',
	LT = 'LT',
	LU = 'LU',
	MT = 'MT',
	NL = 'NL',
	PL = 'PL',
	PT = 'PT',
	CZ = 'CZ',
	RO = 'RO',
	SK = 'SK',
	SI = 'SI',
	SE = 'SE',
	AD = 'AD',
	VA = 'VA',
	SM = 'SM',
	IS = 'IS',
	LI = 'LI',
	NO = 'NO',
	GB = 'GB',
	CH = 'CH'
}
