














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { CountryListAlpha2 } from '@/locales/countries'
import {
  getAutoCompleteResults,
  getCompanyInformation,
  getJumioKYDocVerification,
  getKYState,
  getPlaceDetails,
  JumioKYDocVerification,
  mockJumioCallback,
  updateBeneficialOwners,
  updateCompanyInformation,
  updateKYForm,
} from '@/clients/cpinblocks'
import { appEnv, env } from '@/utils'
import moment from 'moment'
import {
  AddressId,
  Beneficial,
  BusinessLine,
  FamilySituation,
  FundsOrigin,
  Knowledge,
  KYInformation,
  LegalStatus,
  PlannedInvestShares,
  Raise,
  RiskTolerance,
  ShareCapital,
  TaxInformation,
} from '@/models/KYInformation'
import {
  CountryCode,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberFromString,
  PhoneNumber,
} from 'libphonenumber-js'
import CountryAutocomplete from '@/views/CountryAutocomplete.vue'
import VerificationFactorFileInput from '@/components/VerificationFactorFileInput.vue'
import { KYState, KYType, VerificationFactor } from '@/models/KYState'
import DateInput from '@/components/DateInput.vue'
import { ResidenceAddress } from '@/models/ResidenceAddress'
import { VForm } from '@/types'
import { User } from '@/models/User'

@Component({
  components: {
    DateInput,
    VerificationFactorFileInput,
    CountryAutocomplete,
  },
  methods: {
    isValidPhoneNumber,
    getCountryCallingCode,
  },
  computed: {
    FamilySituation () {
      return FamilySituation
    },
    Knowledge () {
      return Knowledge
    },
    RiskTolerance () {
      return RiskTolerance
    },
    PlannedInvestShares () {
      return PlannedInvestShares
    },
    FundsOrigin () {
      return FundsOrigin
    },
    VerificationFactor () {
      return VerificationFactor
    },
    CountryListAlpha2 () {
      return CountryListAlpha2
    },
    ShareCapital () {
      return ShareCapital
    },
    BusinessLine () {
      return BusinessLine
    },
    LegalStatus () {
      return LegalStatus
    },
    KYType () {
      return KYType
    },
    Raise () {
      return Raise
    },
    moment () {
      return moment
    },
  },
})
export default class KYForm extends Vue {
  private addressItems: google.maps.places.AutocompletePrediction[] = []
  private countryCodes = getCountries()
  private debug = env('VUE_APP_ENV') === 'local'
  private jumioKYDocVerification?: JumioKYDocVerification
  // for dev purpose
  private jumioMock = appEnv() !== 'prod'
  private kyInfo = new KYInformation()
  private suffixPhoneNumber = ''
  private prefixPhoneNumber: CountryCode = 'FR'
  private kyState!: KYState
  private loading = true
  private loadingAutocomplete = false
  private maxAttempts = false
  private placeId?: string
  private sendingKY = false
  private sent = false
  private step = 0
  private validAccount = false
  private validIdentity = false
  private validInvestor = false
  private validJumio = false
  private validRecto = false
  private validVerso = false
  private validCompanyId = false
  private validCompanyInfo = false
  private validBeneficial = false
  private companyFound?: boolean | null
  private thirdPartyHosted = false
  private beneficialPanels!: number[]
  private taxInformationCountry!: string
  private taxInformationId!: string
  private kyPartiallyValid = false

  private idDocMode = 'internal' // 'jumio' is another valid value
  private idDocType = 'passeport' // default is set to passeport

  get isCompleteIdentity (): boolean {
    return this.kyInfo.identity?.isComplete && this.kyState.verificationFactors.includes((this.isCorporate ? VerificationFactor.INSTITUTIONAL_INFO : VerificationFactor.RETAIL_INFO))
  }

  get stepKYCConditions (): boolean[] {
    return [
      !this.kyPartiallyValid && this.kyState.isNewUser, // Account Type
      !(this.kyState.verificationFactors.includes(VerificationFactor.PROOF_OF_ADDRESS) &&
          this.kyState.verificationFactors.includes(VerificationFactor.RETAIL_INFO)
      ), // Identity
      !(this.kyState.verificationFactors.includes(VerificationFactor.ID_DOCUMENT)), // ID Document
      !this.kyPartiallyValid && !this.kyState.verificationFactors.includes(VerificationFactor.RETAIL_INFO), // Investor info
      false, // KYB - Company Information
      false, // KYB - Beneficial Owners
    ]
  }

  // NOTE(dkoch): Damn!
  get stepKYBConditions (): boolean[] {
    return [
      !this.kyPartiallyValid && !this.kyState.verificationFactors.includes(VerificationFactor.INSTITUTIONAL_INFO), // Account Type
      !(this.kyState.verificationFactors.includes(VerificationFactor.PROOF_OF_ADDRESS) &&
          this.kyState.verificationFactors.includes(VerificationFactor.INSTITUTIONAL_INFO)
      ), // Identity
      !(this.kyState.verificationFactors.includes(VerificationFactor.ID_DOCUMENT)), // ID Document
      !this.kyPartiallyValid && !this.kyState.verificationFactors.includes(VerificationFactor.INSTITUTIONAL_INFO), // Company identification
      ((this.kyInfo.formUtilities.corporateAccount && this.isLevel2) || this.kyState.kybLevel === 1) &&
      !(
          this.kyInfo.kybInfo.companyInfo && this.kyState.verificationFactors.includes(VerificationFactor.COMPANY_INFO) &&
          this.kyState.verificationFactors.includes(VerificationFactor.KBIS) && this.kyState.verificationFactors.includes(VerificationFactor.COMPANY_STATUS) &&
          this.kyState.verificationFactors.includes(VerificationFactor.BENEF_OWNERS_REGISTRY_DECLARATION) &&
          this.kyState.verificationFactors.includes(VerificationFactor.COMPANY_BANK_STATEMENT) &&
          this.kyState.verificationFactors.includes(VerificationFactor.COMPANY_FINANCIAL_STATEMENT)
      ), // Company information
      ((this.kyInfo.formUtilities.corporateAccount && this.isLevel2) || this.kyState.kybLevel === 1) &&
      !(
          this.kyState.verificationFactors.includes(VerificationFactor.BENEF_OWNERS_ID_DOCUMENTS) &&
          this.kyState.verificationFactors.includes(VerificationFactor.BENEF_OWNERS_INFO)
      ), // Beneficial Owners
    ]
  }

  private get validJumioOrInternal (): boolean {
    if (this.idDocMode === 'jumio') {
      return this.validJumio
    } else if (this.idDocMode === 'internal' && this.idDocType === 'passeport') {
      return this.validRecto
    } else if (this.idDocMode === 'internal') {
      return this.validRecto && this.validVerso
    }
    return false
  }

  private get isValidSiret (): boolean {
    return this.kyInfo?.kybInfo?.companyIdentity?.siret?.length === 14 && !isNaN(Number(this.kyInfo.kybInfo.companyIdentity.siret))
  }

  private get isLevel2 (): boolean {
    return this.kyInfo?.kybInfo?.investorProfessionalProfile?.invest || this.kyInfo?.kybInfo?.investorProfessionalProfile?.raise === Raise.RAISE_ICO
  }

  private get dashboardUrl (): string {
    return window.location.origin + '/dashboard'
  }

  private get rules () {
    return {
      required: [
        (v: string) => !!v || this.$t('rule.requiredField'),
      ],
      booleanRequired: [
        (v?: boolean | null) => (v !== undefined && v !== null) || this.$t('rule.requiredField'),
      ],
      checkboxRequired: [
        (v?: string[]) => (!!v && v.length) > 0 || this.$t('rule.requiredField'),
      ],
      phoneNumberRequired: [
        (v?: string) => (!!v && isValidPhoneNumber(v, this.prefixPhoneNumber)) || this.$t('ky.rule.phoneNumber'),
      ],
      siret: [
        (v?: string) => ((!!v && this.isValidSiret) || this.$t('ky.rule.siret')),
      ],
      numeric: [
        (v: string) => (/^\d*$/.test(v)) || this.$t('ky.rule.numeric'),
      ],
    }
  }

  private get isRaise (): boolean {
    return this.kyInfo.kybInfo.investorProfessionalProfile.raise !== null
  }

  private get isCorporate (): boolean {
    return (this.kyState.isCorporateAccount === null ? (this.kyInfo.formUtilities.corporateAccount === null ? false : this.kyInfo.formUtilities.corporateAccount) : this.kyState.isCorporateAccount)
  }

  receiveMessage (event: any): void {
    // TODO: this is not true anymore on Jumio events ! We were not noticed about this change
    // The new value for event.origin is https://votelabsas.web.emea-1.jumio.ai, not sure it can be considered immutable ...
    // if (event.origin === 'https://lon.netverify.com') {
    try {
      // docsUploaded
      this.validJumio = JSON.parse(event.data).payload?.value === 'success'
    } catch (error) {
      // best effort
    }
  }

  async mounted (): Promise<void> {
    this.kyState = await getKYState(this.$store.state.jwt)
    if (this.$store.state.owner instanceof User &&
        (this.$store.state.owner.institutional !== undefined || this.$store.state.owner.retail !== undefined) &&
        !this.kyState.rejectedValidation && this.kyState.kyLevel === 0 && this.$store.state.owner.verificationFactors.length > 0
    ) {
      this.kyPartiallyValid = true
      Object.assign(this.kyInfo.identity, this.$store.state.owner?.identity)
      this.kyInfo.identity.residenceAddress = new ResidenceAddress()
      Object.assign(this.kyInfo.identity.residenceAddress, this.$store.state.owner?.identity.residenceAddress)
      if (this.$store.state.owner?.isCorporate()) {
        // eslint-disable-next-line no-unused-expressions
        this.$store.state.owner?.institutional?.beneficialOwners?.forEach((beneficial: Beneficial, index: number) => {
          this.kyInfo.kybInfo.beneficials.splice(index, 1, Object.assign(new Beneficial(index), beneficial))
        })
        Object.assign(this.kyInfo.kybInfo.investorProfessionalProfile, this.$store.state.owner?.institutional?.investorProfessionalProfile)
        Object.assign(this.kyInfo.kybInfo.companyIdentity, this.$store.state.owner?.institutional?.companyIdentity)
        this.kyInfo.kybInfo.companyIdentity.residenceAddress = new ResidenceAddress()
        Object.assign(this.kyInfo.kybInfo.companyIdentity.residenceAddress, this.$store.state.owner?.institutional?.companyIdentity?.residenceAddress)
        Object.assign(this.kyInfo.kybInfo.companyInfo, this.$store.state.owner?.institutional?.companyInformation)
      } else {
        Object.assign(this.kyInfo.kycInfo.investorProfile, this.$store.state.owner?.retail?.investorProfile)
      }
      const interval = setInterval(() => {
        if (this.$refs.form1) {
          (this.$refs?.form1 as VForm).validate()
          clearInterval(interval)
        }
      }, 50)
    } else {
      this.kyInfo = this.$store.state.kyInfo
    }
    if (this.kyState.isCorporateAccount !== null) {
      this.kyInfo.formUtilities.corporateAccount = this.kyState.isCorporateAccount
      this.$store.commit('kyInfo', this.kyInfo)
    }
    this.beneficialPanels = [this.kyInfo.kybInfo.beneficials.length - 1]
    this.taxInformationId = this.kyInfo.identity.taxInformation?.[0].taxPayerId ?? ''
    this.taxInformationCountry = this.kyInfo.identity.taxInformation?.[0].country ?? ''
    const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(this.kyInfo.identity.phoneNumber)
    this.suffixPhoneNumber = phoneNumber?.nationalNumber ?? ''
    this.prefixPhoneNumber = phoneNumber?.country ?? 'FR'
    this.step = (this.kyState.isNewUser) ? 0 : 1
    // If the user's data matches all the queries to be eligible to proceed further
    if (this.kyState.acceptedVerificationFactors.includes(VerificationFactor.ID_DOCUMENT)) {
      this.validJumio = true
    } else if (!this.kyState.pendingVerificationFactors.includes(VerificationFactor.ID_DOCUMENT)) {
      const response = await getJumioKYDocVerification(this.$store.state.jwt)
      if (response !== undefined) {
        this.jumioKYDocVerification = typeof response === 'object' ? response : undefined
        this.maxAttempts = typeof response === 'boolean' ? response : false
      }
    }
    this.loading = false
    window.addEventListener('message', this.receiveMessage, false)
  }

  async updateInfo (): Promise<void> {
    const state = this.$store.state
    this.sendingKY = true
    this.sent = (this.$store.state.owner.kyLevel < 1) ? await updateKYForm(state.jwt, state.kyInfo) : true
    this.sent &&= (this.isLevel2 && this.stepKYBConditions[4]) ? await updateCompanyInformation(state.jwt, state.kyInfo.kybInfo.companyInfo) : true
    this.sent &&= (this.isLevel2 && this.stepKYBConditions[5]) ? await updateBeneficialOwners(state.jwt, state.kyInfo.kybInfo.beneficials) : true
    this.sendingKY = false
  }

  async autoCompleteCompanyForm (): Promise<void> {
    if (this.isValidSiret) {
      this.companyFound = null
      this.loadingAutocomplete = true
      const response = await getCompanyInformation(this.kyInfo.kybInfo.companyIdentity.siret)
      if (response) {
        this.companyFound = true
        Object.assign(this.kyInfo.kybInfo.companyIdentity, response)
        this.kyInfo.kybInfo.companyIdentity.residenceAddress = new ResidenceAddress('FR', response.city, response.postCode, response.street)
        if (!this.kyInfo.kybInfo.companyIdentity.name || this.kyInfo.kybInfo.companyIdentity.name === '') this.kyInfo.kybInfo.companyIdentity.name = response.alternativeName
      } else {
        this.companyFound = false
      }
      this.loadingAutocomplete = false
      this.$store.commit('kyInfo', this.kyInfo)
    }
  }

  updateRaise (event: boolean): void {
    this.kyInfo.kybInfo.investorProfessionalProfile.raise = (event) ? Raise.UNKNOWN : null
    this.$store.commit('kyInfo', this.kyInfo)
  }

  getDisplayStepCondition (step: number): boolean {
    return this.isCorporate ? this.stepKYBConditions[step - 1] : this.stepKYCConditions[step - 1]
  }

  private getCurrentStep (initialStep: number): number {
    const conditions = this.isCorporate ? this.stepKYBConditions : this.stepKYCConditions
    let step = 0
    for (let i = 0; i < initialStep && i < conditions.length; i++) {
      if (conditions[i]) step++
    }
    return step
  }

  private addBeneficial (): void {
    this.kyInfo.kybInfo.beneficials.push(new Beneficial(this.kyInfo.kybInfo.beneficials.length))
    this.beneficialPanels = [this.kyInfo.kybInfo.beneficials.length - 1]
    this.$store.commit('kyInfo', this.kyInfo)
  }

  private removeBeneficial (index: number): void {
    this.kyInfo.kybInfo.beneficials.splice(index, 1)
    this.beneficialPanels = [this.kyInfo.kybInfo.beneficials.length - 1]
    this.$store.commit('kyInfo', this.kyInfo)
  }

  private updateTaxInformation (): void {
    this.kyInfo.identity.taxInformation = (!this.taxInformationId || !this.taxInformationCountry) ? undefined : [new TaxInformation(this.taxInformationId, this.taxInformationCountry)]
    this.$store.commit('kyInfo', this.kyInfo)
  }

  private goToDashboard (): void {
    window.location.href = window.location.origin + '/dashboard'
  }

  private updatePhoneNumber (): void {
    if (isValidPhoneNumber(this.suffixPhoneNumber, this.prefixPhoneNumber)) {
      this.kyInfo.identity.phoneNumber = parsePhoneNumber(this.suffixPhoneNumber, this.prefixPhoneNumber).number
      this.$store.commit('kyInfo', this.kyInfo)
    }
  }

  private async updateAutoCompletedAddresses (value: string): Promise<void> {
    if (value == null) {
      if (this.debug) {
        console.log('No input provided')
      }
      return
    }
    if (this.debug) {
      console.log(`Retrieving predictions for input ${value}`)
    }
    this.loadingAutocomplete = true
    this.addressItems = await getAutoCompleteResults(this.$store.state.jwt, value)
    if (this.debug) {
      console.log(this.addressItems)
    }
    this.loadingAutocomplete = false
  }

  private async updateWithPlace (arg?: google.maps.places.AutocompletePrediction | string | null): Promise<void> {
    if (arg === null || arg === undefined) {
      this.kyInfo.identity.residenceAddress.street = ''
      this.manuallyUpdateAddress()
    } else if (typeof arg === 'string') {
      if (this.debug) {
        console.log(`User input is  ${arg}`)
      }
      this.kyInfo.identity.residenceAddress.street = arg
      this.manuallyUpdateAddress()
    } else {
      if (this.debug) {
        console.log(`The selected place_id is ${arg.place_id}`)
      }
      const place = await getPlaceDetails(this.$store.state.jwt, arg.place_id)
      this.fillAddressFieldsFromAutoCompleteResult(place)
    }
  }

  private manuallyUpdateAddress (): void {
    this.kyInfo.identity.addressId = undefined
    this.$store.commit('kyInfo', this.kyInfo)
  }

  private fillAddressFieldsFromAutoCompleteResult (place: google.maps.places.PlaceResult | undefined): void {
    this.kyInfo.identity.residenceAddress.street = place?.name ?? ''
    this.placeId = place?.place_id
    if (this.placeId !== undefined) {
      this.kyInfo.identity.addressId = new AddressId('google', this.placeId)
    }
    place?.address_components?.map(component => {
      const componentType = component.types[0]
      if (this.debug) {
        console.log(`${componentType}: ${component.long_name}`)
      }
      switch (componentType) {
        case 'street_number': {
          break
        }
        case 'route': {
          break
        }
        case 'postal_code': {
          this.kyInfo.identity.residenceAddress.postCode = component.long_name
          this.$store.commit('kyInfo', this.kyInfo)
          break
        }
        case 'postal_code_suffix': {
          break
        }
        case 'locality':
          this.kyInfo.identity.residenceAddress.city = component.long_name
          this.$store.commit('kyInfo', this.kyInfo)
          break
        case 'country':
          this.kyInfo.identity.residenceAddress.country = component.short_name
          this.$store.commit('kyInfo', this.kyInfo)
          break
      }
    })
  }

  private async simulateReceivedJumioCallback (): Promise<void> {
    await mockJumioCallback(this.$store.state.owner?.ownerId)
    this.validJumio = true
  }
}
